import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontSizes, colors, margins, mobileThresholdPixels } from '../home/v2/StyledComponents';

const Container = styled.div`
  display: inline-block;
  width: ${props => props.large ? '400px' : '300px'};
  margin: 0px 20px;
  padding: ${margins.l} ${margins.l} ${margins.m};
  background-color: ${colors.lightGrey};
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 50vw;
    padding: ${margins.m} ${margins.m} ${margins.s};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 375px;

  @media (max-width: ${mobileThresholdPixels}) {
    min-height: 305px;
  }
`;

const Text = styled.p`
  text-align: center;
  font-size: ${fontSizes.l};
  color: ${colors.navy};
  margin-bottom: ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    margin-bottom: ${margins.m};
  }
`;

const Author = styled.p`
  color: ${colors.navy};
  font-size: ${fontSizes.xl};
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const Img = styled.img`
  object-fit: cover;
  height: 420px;
  width: 300px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 40px);
  }
`;

const Feedback = ({ text, dataIndex, author, image, large }) => (
  <Container data-index={dataIndex} large={large}>
    {image ?
      <div>
        <Img src={image} alt="" />
      </div> :
      <Column alignItems={'center'}>
        {text && <Text>{text}</Text>}
        {author && <Author>{author}</Author>}
      </Column>
    }
  </Container>
);

Feedback.propTypes = {
  dataIndex: PropTypes.string.isRequired,
  text: PropTypes.node,
  image: PropTypes.string,
  author: PropTypes.node,
  large: PropTypes.bool,
};

Feedback.defaultProps = {
  text: '',
  image: '',
  author: '',
  large: false,
};

export default Feedback;
