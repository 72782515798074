import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { fontSizes, colors, mobileThresholdPixels, Icon, YellowLineH, Dot } from './v2/StyledComponents';
import isMobile from '../../services/isMobile';

const Container = styled.div`
  font-family: Roboto;
  margin: 10px 95px;
  flex: 1;
  text-align: center;
  max-width: 600px;
  display: flex;
  flex-direction: row;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 30px 5px;
    align-items: center;
  }

  &:hover #onHoverDisplay {display: flex;}

  &:hover #onHoverHide {display: none;}

  &:hover #line {
    border-width: 2px;
    width: 90%;
  }
`;

const Body = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  font-size: ${fontSizes.l};

  @media (max-width: ${mobileThresholdPixels}) {
    display: flex;
    flex-direction: ${props => !props.inverted ? 'row' : 'row-reverse'};
    justify-content: space-around;
  }
`;

const Title3 = styled.h3`
  font-family: Gotham;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  min-width: 200px;
  color: ${colors.navy};
  font-size: 24px;
  font-weight: 300;
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 16px;
    min-width: unset;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => !props.unsetHeight && 'min-height: 300px'};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 87vw;
    min-height: 190px;
  }
`;

const Span1 = styled.span`
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 50%;
  }
`;

const Block = ({ children, icon, title, framed, inverted }) => (
  <Container inverted={inverted}>
    {!isMobile() ?
      <Column>
        {title && <Title3>{title}</Title3>}
        {title && <YellowLineH id={'line'} />}
        {!framed && icon && <Icon id={'onHoverHide'} big src={icon} alt="" />}
        {framed && icon && <div><Icon id={'onHoverHide'} big src={icon} alt="" /></div>}
        <Body id={'onHoverDisplay'}>
          <Dot />
          {children}
        </Body>
      </Column>
    :
      <Column inverted={inverted}>
        {title && <Title3>{title}</Title3>}
        {title && <YellowLineH focus />}
        <Body inverted={inverted}>
          {!framed && icon && <Icon big src={icon} alt="" />}
          {framed && icon && <div><Icon big src={icon} alt="" /></div>}
          <Span1>{children}</Span1>
        </Body>
      </Column>
  }
  </Container>
);

Block.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  framed: PropTypes.bool,
  inverted: PropTypes.bool,
};

Block.defaultProps = {
  icon: '',
  title: '',
  framed: false,
  inverted: false,
};

export default Block;
