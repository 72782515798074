import React from 'react';
import styled from 'styled-components';

import Slider from '../home/v2/Slider';
import { trackEvent } from '../../services/analytics';
import { mobileThresholdPixels, margins, colors, Row, Title2, Button } from '../home/v2/StyledComponents';
import Dots from '../home/v2/Dots';
import Feedback from '../home/TillisteFeedback';

import leftArrow from '../../assets/arrow.left.navy.svg';
import rightArrow from '../../assets/arrow.right.navy.svg';

const Container = styled.div`
  padding: 20px 0px 40px;
  background-color: ${colors.linen};
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 450px;
  margin: ${margins.l} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const ControlContainer = styled.div``;

const ControlImg = styled.img`
  margin: 0px ${margins.l};
  width: 25px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 20px;
    margin: 0px;
  }
`;

const feedbacks = [
  {
    id: '0',
    text: `Être Tilliste c’est avoir la possibilité d’être toujours une entrepreneure qui décide de ses missions en
      fonction de ses préférences et son planning. Pouvoir mêler le plaisir de la couture et celui de la rencontre.
      C’est pour moi l’occasion d’avoir un complément de revenu en rapport avec mon métier de cœur, tout en étant
      libre de développer mes autres projets business.`,
    author: 'Daphné',
  },
  {
    id: '1',
    text: `Je me suis inscrite sur Tilli il y a quelques semaines et après 3 missions j'ai pu m'acheter une super
    machine industrielle ! Tilli c'est une application qui me permet de proposer mes services de couturière contre une
    rémunération. C'est moi qui établis mes compétences, ce que je veux et ne veux pas faire et c'est également moi qui
    choisis d'accepter ou non une mission en fonction de mon emploi du temps !`,
    author: 'Morgane',
  },
  {
    id: '2',
    text: `Je suis contente d'être avec Tilli, je peux concilier ma vie d'étudiante avec les missions sur Paris, et le
    plus génial c'est de pratiquer en même temps que j'apprends ! Tilli m'as permis de financer mes fournitures et
    tissus pour mon projet de collection de fin d'année. Merci ! Les clients de Tilli sont très accueillants,
    enthousiastes et nous avons de très bons échanges, je suis contente d'avoir fais leur connaissance.`,
    author: 'Audrey',
  },
];

function trackClick() {
  trackEvent('click', 'devenir-couturier-formulaire_paroles-tillistes');
}

class TillisteFeedbacks extends React.Component {
  render() {
    return (
      <Container>
        <Dots big />
        <Title2 big yellow>Paroles de Tillistes</Title2>
        <SliderContainer>
          <ControlContainer>
            <ControlImg src={leftArrow} onClick={() => this.track.prev()} />
          </ControlContainer>
          <Slider viewsToShow={1} ref={(c) => { this.track = c; }} infinite>
            {feedbacks.map(feedback =>
              <Feedback
                large
                dataIndex={feedback.id}
                key={feedback.id}
                {...feedback}
              />,
            )}
          </Slider>
          <ControlContainer>
            <ControlImg src={rightArrow} onClick={() => this.track.next()} />
          </ControlContainer>
        </SliderContainer>
        <Row justifyContent={'center'}>
          <a
            target="_blank" rel="noreferrer noopener"
            href="https://docs.google.com/forms/d/e/1FAIpQLScvR2ul5h35xj4qXnM0dTLj3cEkaRrmY-7Y9-eZyismNICHog/viewform"
            onClick={trackClick}
          >
            <Button>{'Devenez Tilliste'}</Button>
          </a>
        </Row>
      </Container>
    );
  }
}

export default TillisteFeedbacks;
