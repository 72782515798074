import React from 'react';
import styled from 'styled-components';

import { trackEvent } from '../../services/analytics';
import isMobile from '../../services/isMobile';
import Dots from '../home/v2/Dots';
import { margins, colors, mobileThresholdPixels, Button, Title2 } from '../home/v2/StyledComponents';
import NumberedBlock from '../home/NumberedBlock';

// cSpell:ignore Tilly
const Texts = [
  {
    id: 1,
    title: 'Missions',
    alt: 'Appli mobile Tilli Tilly',
    paragraph: !isMobile() ?
      <span>
        {'Acceptez ou refusez'}<br />{"les missions que l'on"}<br />
        {'vous confie via votre'}<br />{'application mobile.'}
      </span> :
      <span>
        {'Acceptez ou refusez les missions'}<br />{"que l'on vous confie via votre"}<br />
        {'application mobile.'}
      </span>,
  },
  {
    id: 2,
    title: 'Clients',
    alt: 'Client robe de mariée Tilly',
    paragraph: !isMobile() ?
      <span>
        {'Rendez-vous chez'}<br />{'votre client et mettez'}<br />
        {'votre savoir-faire à'}<br />{'disposition.'}
      </span> :
      <span>
        {'Rendez-vous chez votre client et'}<br />{'mettez votre savoir-faire à'}<br />{'disposition'}
      </span>,
  },
  {
    id: 3,
    title: 'Paiements',
    alt: 'Retouche express paris',
    paragraph: !isMobile() ?
      <span>
        {'Suivez vos paiements'}<br />{'sécurisés directement'}<br />
        {"sur l'application"}<br />{'mobile Tilli.'}
      </span> :
      <span>
        {'Suivez vos paiements sécurisés'}<br />{"directement sur l'application mobile"}<br />{'Tilli'}
      </span>,
  },
];

const Container = styled.div`
  font-family: Roboto;
  padding: 40px 0px 60px;
  background-color: ${colors.lightGrey};
  text-align: center;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px 0px ${margins.l};
  text-align: left;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const P = styled.p`
  color: ${colors.navy};
`;

function trackClick() {
  trackEvent('click', 'devenir-couturier-formulaire_le-comment');
}

const TheHow = () => (
  <Container id="TheHow">
    <Dots big />
    <Title2 big yellow>La vie d’un Tilliste</Title2>
    <Row>
      {Texts.map(content =>
        <NumberedBlock key={content.id} number={content.id} title={content.title} alt={content.alt}>
          <P>{content.paragraph}</P>
        </NumberedBlock>,
      )}
    </Row>
    <a
      target="_blank" rel="noreferrer noopener"
      href="https://docs.google.com/forms/d/e/1FAIpQLScvR2ul5h35xj4qXnM0dTLj3cEkaRrmY-7Y9-eZyismNICHog/viewform"
      onClick={trackClick}
    >
      <Button transparent>{'Devenez couturier'}</Button>
    </a>
  </Container>
);

export default TheHow;
